$upload-invalid: $ni-alert-fill !default;
$upload-valid: $ni-check-circle-fill !default;
$upload-error: $ni-cross-circle-fill !default;
//Passcode Toggle
.passcode{
    &-icon {
        display: none;
        &.icon-show {
            display: block;
            .is-shown &{
                display: none;
            }
            .is-hidden &{
                display: block;
            }
        }
        &.icon-hide {
            .is-shown &{
                display: block;
            }
            .is-hidden &{
                display: none;
            }
        }
    }
}

// Dropdown
.form-dropdown{
    position: absolute;
    @if($rtl==false){
        right: 0;
    }
    @if($rtl==true){
        left: 0;
    }
    top: 0;
    bottom: 0;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $base-light;
    > div > span{
        margin:0 0.5rem;
    }
    .dropdown{
        > a{
            display: inline-flex;
        }
    }
}

// clipboard
.form-clip,.form-text-hint{
    position: absolute;
    @if($rtl==false){
        right: $input-height-border;
    }
    @if($rtl==true){
        left: $input-height-border;
    }
    top: $input-height-border;
    height: calc(#{$input-height-inner} - #{$input-height-border});
    display: flex;
    align-items: center;
    color: $accent-color;
    @if($rtl==false){
        padding-left: 1rem;
        padding-right: .75rem;
    }
    @if($rtl==true){
        padding-right: 1rem;
        padding-left: .75rem;
    }
    background: $white;
    border-radius: $input-border-radius;
    &.sm{
        height: $input-height-sm;
    }
    &.lg{
        height: $input-height-lg;
    }
    &.xl{
        height: $input-height-xl;
    }
    .icon + span,span + .icon{
        @if($rtl==false){
            margin-left: 0.25rem;
        }
        @if($rtl==true){
            margin-right: 0.25rem;
        }
    }
}

//Upload

.nk-upload{
    &-input{
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(2.125rem + 2px);
        margin: 0;
        opacity: 0;
    }
    &-label{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem;
        width: 100%;
        min-height: 150px;
        border-radius: $border-radius;
        border: 1px dashed $border-light;
        padding-bottom: 0;
    }
    &-init{
        display: block;
        text-align: center;
    }
    &-files{
        padding-top: 2rem;
        display: flex;
        justify-content: center;
        margin: -0.5rem;
        padding-bottom: 2rem;
        li{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 0.5rem;
            img{
                width: 60px;
                flex-shrink: 0;
                border-radius: $border-radius;
            }
        }
        .is-multiple &{
            li{
                img{
                    width: 40px;
                }
                .nk-upload-name{
                    display: none;
                }
            }
        }
    }
    &-name{
        margin: .5rem 0 0;
        color: $base-text;
        font-size:.8125rem;
        font-weight: $fw-medium;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-response{
        @if($rtl==false){
            margin-left: auto;
        }
        @if($rtl==true){
            margin-right: auto;
        }
        display: inline-flex;
        align-items: center;
        .message{
            font-size: $fx-sz-12;
            color:$base-light;
            + .icon{
                @if($rtl==false){
                    margin-left: 0.5rem;
                }
                @if($rtl==true){
                    margin-right: 0.5rem;
                }
            }
        }
        .icon{
            &:after{
                font-family: $nk-dashlite-font;
            }
            + .message{
                @if($rtl==false){
                    margin-left: 0.5rem;
                }
                @if($rtl==true){
                    margin-right: 0.5rem;
                }
            }
        }
        &.invalid{
            .icon{
                color: $warning;
                &:after{
                    content: $upload-invalid;
                }
            }
        }
        &.valid{
            .icon{
                color: $success;
                &:after{
                    content: $upload-valid;
                }
            }
        }
        &.error{
            .icon{
                color: $danger;
                &:after{
                    content: $upload-error;
                }
            }
        }
    }
    &-foot{
        display: flex;
        justify-content: space-between;
        border-top: 1px dashed $border-light;
        padding: 1rem 1.25rem;
        margin: 0 -1.5rem;
        > span, > button{
            margin: .25rem;
        }
    }
}

.form {
    &-editor-custom {
        textarea {
            border-radius: $border-radius $border-radius  0 0;
        }
    }
    &-editor-action {
        border: 1px solid $border-color;
        border-top: 0;
        border-radius: 0 0 $border-radius $border-radius;
        padding: .25rem .5rem;
        > .link{
            padding: .5rem;
            &.collapsed {
                color: $base-light;
            }
            .icon {
                @if($rtl==false){
                    margin-right: .25rem;
                }
                @if($rtl==true){
                    margin-left: .25rem;
                }
            }
        }
    }
    &-editor-btn-group, &-btn-group{
        display: flex;
        align-items: center;
        margin: -0.25rem;
        flex-wrap: wrap;
        li {
            padding: .25rem;
            line-height: 0.5rem;
        }
    }
    &-btn-group {
        li:first-child {
            @if($rtl==false){
                margin-right: .5rem;
            }
            @if($rtl==true){
                margin-left: .5rem;
            }
        }
    }
    &-btn-secondary {
        @if($rtl==false){
            margin-left: auto;
        }
        @if($rtl==true){
            margin-right: auto;
        }
    }
}


// number
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}

.number{
    &-spinner{
        padding-left: $input-padding-x + $btn-line-height + $btn-padding-y*2;
        padding-right: $input-padding-x + $btn-line-height + $btn-padding-y*2;
        text-align: center;
        &:focus{
            border-color: $input-border-color;
        }
        &-btn{
            position: absolute;
            top: 0;
        }
    }
    &-plus{
        @if($rtl==false){
            right: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        @if($rtl==true){
            left: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    &-minus{
        @if($rtl==false){
            left: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        @if($rtl==true){
            right: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

// for date range
.input-group-addon{
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    background-color: $lighter;
    border: $input-border-width solid $input-border-color;
    font-size: 0.75rem;
    border-radius: $border-radius;
    &:not(:last-child){
        @if($rtl==false){
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        @if($rtl==true){
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &:not(:first-child){
        @if($rtl==false){
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        @if($rtl==true){
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}