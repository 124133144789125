.alert{
    &-cta{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-text{}
    &-actions{
        display: inline-flex;
        align-items: center;
        a+a{
            @if($rtl==false){
                margin-left: 1rem;
            }
            @if($rtl==true){
                margin-right: 1rem;
            }
        }
    }
}