.select2-container--default {
  @import "single";
  @import "multiple";

  .select2-search--dropdown {
    .select2-search__field {
      border: 1px solid $select2-border-color;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: textfield;
    }
  }

  .select2-results > .select2-results__options {
    max-height: 210px;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: $select2-dropdown-item-disabled;
    }

    &[aria-selected=true] {
      background-color: $select2-dropdown-item-active;
    }

    .select2-results__option {
      @if($rtl==false){
        padding-left: 1em;
      }
      @if($rtl==true){
        padding-right: 1em;
      }
      .select2-results__group {
        @if($rtl==false){
          padding-left: 0;
        }
        @if($rtl==true){
          padding-right: 0;
        }
      }

      .select2-results__option {
        @if($rtl==false){
          margin-left: -1em;
          padding-left: 2em;
        }
        @if($rtl==true){
          margin-right: -1em;
          padding-right: 2em;
        }
        .select2-results__option {
          @if($rtl==false){
            margin-left: -2em;
            padding-left: 3em;
          }
          @if($rtl==true){
            margin-right: -2em;
            padding-right: 3em;
          }
          .select2-results__option {
            @if($rtl==false){
              margin-left: -3em;
              padding-left: 4em;
            }
            @if($rtl==true){
              margin-right: -3em;
              padding-right: 4em;
            }
            .select2-results__option {
              @if($rtl==false){
                margin-left: -4em;
                padding-left: 5em;
              }
              @if($rtl==true){
                margin-right: -4em;
                padding-right: 5em;
              }
              .select2-results__option {
                @if($rtl==false){
                  margin-left: -5em;
                  padding-left: 6em;
                }
                @if($rtl==true){
                  margin-right: -5em;
                  padding-right: 6em;
                }
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color:$select2-dropdown-item-active;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}
