.select2-selection--single {
  height: $select2-input-height;
  font-family: $select2-font-family;
  font-size: $select2-font-size;
  font-weight: $select2-font-weight;
  background-color: $select2-bg-color;
  border: $select2-border-width solid $select2-border-color;
  border-radius:$select2-border-radius;
  box-shadow:$select2-box-shadow;
  transition:$select2-transition;
  .select2-sm &{
    font-size: $select2-font-size-sm;
  }
  .select2-lg &{
    font-size: $select2-font-size-lg;
  }
  .select2-xl &{
    font-size: $select2-font-size-xl;
  } 
  &:focus{
    box-shadow: $select2-focus-box-shadow;
    border-color: $select2-focus-border-color;
    outline: none;
  }
  .select2-selection__rendered {
    color: $select2-color;
    line-height: $select2-line-height;
    @if($rtl==false){
      padding: $select2-padding-y $select2-input-height $select2-padding-y $select2-padding-x;
    }
    @if($rtl==true){
      padding: $select2-padding-y $select2-padding-x $select2-padding-y $select2-input-height;
    }
    &:focus{
      outline: none;
    }
  }

  .select2-selection__clear {
    cursor: pointer;
    @if($rtl==false){
      float: right;
    }
    @if($rtl==true){
      float: left;
    }
    font-weight: bold;
  }

  .select2-selection__placeholder {
    color: $select2-placeholder-color;
  }

  .select2-selection__arrow {
    height: $select2-input-height;
    position: absolute;
    top: 0;
    @if($rtl==false){
      right: 0;
    }
    @if($rtl==true){
      left: 0;
    }
    width: $select2-input-height;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after{
      font-family: $select2-arrow-font-family;
      content: $select2-arrow-down;
      line-height: 1;
    }
    b {
      display: none;
    }
  }
}
&.select2-sm{
  .select2-selection--single {
    height: $select2-input-height-sm;
    font-size: $select2-font-size-sm;
    .select2-selection__rendered {
      line-height: $select2-line-height-sm;
      @if($rtl==false){
        padding: $select2-padding-y-sm $select2-input-height-sm $select2-padding-y-sm $select2-padding-x-sm;
      }
      @if($rtl==true){
        padding: $select2-padding-y-sm $select2-padding-x-sm $select2-padding-y-sm $select2-input-height-sm;
      }
    }
    .select2-selection__arrow {
      height: $select2-input-height-sm;
      width: $select2-input-height-sm;
    }
  }
}
&.select2-lg{
  .select2-selection--single {
    height: $select2-input-height-lg;
    font-size: $select2-font-size-lg;
    .select2-selection__rendered {
      line-height: $select2-line-height-lg;
      @if($rtl==false){
        padding: $select2-padding-y-lg $select2-input-height-lg $select2-padding-y-lg $select2-padding-x-lg;
      }
      @if($rtl==true){
        padding: $select2-padding-y-lg $select2-padding-x-lg $select2-padding-y-lg $select2-input-height-lg;
      }
    }
    .select2-selection__arrow {
      height: $select2-input-height-lg;
      width: $select2-input-height-lg;
    }
  }
}
&.select2-xl{
  .select2-selection--single {
    height: $select2-input-height-xl;
    font-size: $select2-font-size-xl;
    .select2-selection__rendered {
      line-height: $select2-line-height-xl;
      @if($rtl==false){
        padding: $select2-padding-y-xl $select2-input-height-xl $select2-padding-y-xl $select2-padding-x-xl;
      }
      @if($rtl==true){
        padding: $select2-padding-y-xl $select2-padding-x-xl $select2-padding-y-xl $select2-input-height-xl;
      }
    }
    .select2-selection__arrow {
      height: $select2-input-height-xl;
      width: $select2-input-height-xl;
    }
  }
}

&.select2-container--below.select2-container--open{
  .select2-selection--single{
    border-radius: $select2-border-radius $select2-border-radius 0 0;
  }
}
&.select2-container--above.select2-container--open{
  .select2-selection--single{
    border-radius: 0 0 $select2-border-radius $select2-border-radius;
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    border-color: $select2-border-color;
    background-color: rgba($select2-border-color, 0.3);
    cursor: default;
    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    border-color: $select2-open-border-color !important;
    .select2-selection__arrow {
      &:after{
        content: $select2-arrow-up;
      }
    }
  }
}