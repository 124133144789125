.select2-selection--multiple {
  box-sizing: border-box;

  cursor: pointer;
  display: block;

  min-height: $select2-input-height;

  user-select: none;
  -webkit-user-select: none;
  .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    @if($rtl==false){
      padding-left: 8px;
    }
    @if($rtl==true){
      padding-right: 8px;
    }
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
&.select2-lg .select2-selection--multiple{
  min-height: $select2-input-height-lg;
}
.select2-search--inline {
  .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
}
