@charset "UTF-8";
/*!
* Template Name: DashLite
* Author: Softnio
* Author URI: http://themeforest.net/user/softnio
* Version: 2.9.1
* Updated: 01.16.2022
**/

/* 
 * IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file.
 * It will helps you to easy update of the template.
 **/

/*
----------------------
## Table Contents ##
* 01. FONTS
* 02. VENDORS (Bootstraps, NioIcon, Select2 etc)
* 03. LAYOUTS
* 04. UTILITES
* 05. COMPONENTS
* 06. INDIVIDUL CONCEPTS
* 07. PREVIEWS
---------------------- **/

////////////////////////// DO NOT REMOVE BELOW ///////////////////////

// SET RTL OPTION
$rtl: false;

// Included Global Variables and Functions
@import "app_variables";

// Included Fonts
/** 01. FONTS */
@import "core/fonts/roboto";
// @import "core/fonts/nunito";
// @import "vendors/bootstrap-icons/bootstrap-icons";

// Included Bootstarp
@import "extend/bootstrap/variables";
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";

// Include Other Vendors
@import "vendors/bundle";

// Include Core Dashlite Style
@import "core/style";

////////////////////////// DO NOT REMOVE ABOVE ///////////////////////

////// GLOBAL INCLUDED
@import "global/style";

//////  INDIVIDUL CONCEPTS 
@import "subscription/style";

//////  APPLICATONS - Common Uses
@import "apps/asterisk"; 
@import "apps/reply-comment"; 
@import "apps/attach"; 

//////  Apps
// @import "apps/messages";  		// - Support Messages
@import "apps/inbox";  			// - Inbox/ Mail App
// @import "apps/file-manager";  	// - File Manager
// @import "apps/chats";  			// - Chats

///////  PREVIEW PURPOSE 
// @import "core/preview";			// Can be remove on real projoect

///////  UI/MODE OVERRIDE
@import "core/mode-override";

///////  DARK MODE SKIN
// @import "core/dark-skin";

/*! END @iO */
////////////////////////// END STYLESHEET ////////////////////////////