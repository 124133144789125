// @import "react-toastify/dist/ReactToastify.min.css";
@import 'app_variables';

.cursor-pointer {
  cursor: pointer;
}

.min-h-600 {
  min-height: 600px;
}

.tagify {
  border: none;

  &__tag > div{
    padding-bottom: .35rem;
  }

  &__tag__removeBtn {
    margin-bottom: .25rem;
  }
}

.border-color-light {
  border-color: $light-200!important;
}
